* {
    margin-top: auto;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}
.logo {
    max-width: 270px;
    margin-left: 1px;
    max-height: 80px;
   }
  
  .header {
    text-align: center;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 9999; /* Adjust as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
   
  }
  
.container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height of the viewport */
    max-width: 100vw; /* Full width of the viewport */
    padding: 2px;
}

.top-half {
    position: relative;
    width: 100%;
    height: auto; /* Adjusted height for the top half */
}

.full-image {
    width: 100%;
    height: auto;
    /* object-fit: cover; */
    display: block;
    margin-top: 80px;
}

.img{
    max-height: 100%;
}
.overlay {
    position: absolute;
    top: 15%;
    right: 100px;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    z-index: 1;
}

.form-image {
    width: 280px;
    height: auto;
    border-radius: 15px;
}

.form-container {
    padding: 10px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    width: 270px;
    height: auto;
    position: relative;
}

.heading {
    color: #a67c00;
    font-size: 1.2em;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center !important;
}

.form-container label {
    margin-bottom: 8px;
    font-size: 0.9em;
}

.form-container input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-size: 0.9em;
}

.book-now {
    background-image: linear-gradient(to right, #804000, #be8d48, #804000);
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;
    display: block; /* Make button block level */
    margin: 0 auto; /* Center align button */
    z-index: 3; /* Ensure button is on top */
}

.bottom-half {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0; /* Remove padding to reduce space */
    margin-top: -40px; /* Adjust margin to reduce space */
}

.bottom-half p {
    font-family: 'Work Sans' !important;
    font-weight: bold;
    max-width: 50%;
    margin: 0 auto; /* Center aligns the text horizontally */
}

.bottom-half-image {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    /* max-width: 80%; */
}

.bottom-half-image1 {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    max-width: 90%;
}

.bottom-half-image img {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

.error {
    color: red;
    font-size: 0.8em;
    margin-top: -8px;
    margin-bottom: 10px;
}

.mobile-image {
    display: none;
}

/* Show the web image by default */
.web-image {
    display:  block;
}

.form-image {
    display: block;
}

.terms {
    text-align: center;
    margin-top: -20px;
}

.terms-link {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Full height for centering */
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



.modal-body img {
    width: 100%;
    height: auto;
}

.close-button {
    position: absolute;
    top: 0px; /* Increase distance from the top */
    right: 0px; /* Increase distance from the right */
    background: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10; /* Ensure the close button stays on top of the image */
}
.thank-you-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.thank-you-image {
    max-width: 90%; /* Adjust max-width as needed */
    max-height: 90%; /* Adjust max-height as needed */
    object-fit: contain; /* Maintain aspect ratio */
}

/* Mobile view */
@media (max-width: 768px) {
    .container {
        height: auto;
    }

    .top-half {
        height: auto;
        position: static;
    }

    .full-image {
        height: auto;
    }

    .overlay {
        position: static;
        width: 100%;
        display: block;
        padding: 0;
        box-sizing: border-box;
    }

    .form-container {
        width: 100%;
        margin-top: 10px;
    }

    .bottom-half {
        padding: 10px;
        margin-top: -50px;
    }

    .bottom-half-image1 {
        display: none;
    }
    .bottom-half-image {
        display: flex;
        margin-bottom: 20px;
    }

    .web-image {
        display: none;
    }

    .mobile-image {
        display: block;
    }

    .form-image {
        display: none;
    }

    .book-now {
        margin-top: 20px; /* Increase top margin for better spacing */
    }

    .bottom-half p {
        font-weight: bold;
        max-width: 90%;
        margin-top: 70px; 
        padding: 10px;
    }

    .terms {
        text-align: center;
        margin-top: 10px;
    }

    .modal-content {
        width: 90%;
        height: auto;
    }

    .close-button {
        top: 5px;
        right: 5px;
        font-size: 1.2rem;
    }
    .logo {
        max-height: 50%;
      }
}

@media (min-width: 769px) {
    .carousel-container {
        display: none; /* Hide the carousel container on larger screens */
    }
}
